
<div role="list">
    <div role="listitem" class="cust-list-item" *ngFor="let favo of listOfFavos; let index = index">
        <div mat-line  *ngIf="linguaCorsaInHtml">
            <a  routerLinkActive='active' [routerLink]="favo.id" > {{ favo.def }}</a>
        </div>
        <div mat-line class="list-secondary" *ngIf="linguaCorsaInHtml" >
            <a routerLinkActive='active' [routerLink]= "favo.id" > {{ favo.FRANCESE }} </a>
        </div>
        <div mat-line *ngIf="!linguaCorsaInHtml" >
            <a routerLinkActive='active' [routerLink]= "favo.id" > {{ favo.FRANCESE }} </a>
        </div>
        <div mat-line class="list-secondary" *ngIf="!linguaCorsaInHtml">
            <a routerLinkActive='active' [routerLink]= "favo.id"> {{ favo.def }}</a>
        </div>
    </div>
</div>