<div class="mat-display-1 cust-results">
    <div  *ngFor="let switch of arrayOfSwitchesKeys" >
        <div *ngIf="linguaCorsaInHtml" >
            <mat-label fxAlign="left" fxFlex="90%" >{{ switchesNamesCorsu[switch] }}</mat-label>
            <mat-slide-toggle fxAlign="right" [(ngModel)]="arrayOfSwitches[switch]" ></mat-slide-toggle>
        </div>
        <div *ngIf="!linguaCorsaInHtml">
            <mat-label fxAlign="left" fxFlex="90%" >{{ switchesNamesFrancais[switch] }}</mat-label>
            <mat-slide-toggle [(ngModel)]="arrayOfSwitches[switch]"></mat-slide-toggle>
        </div>
    </div>
</div>