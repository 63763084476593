import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, ParamMap } from '@angular/router';
import { WordFetcherService } from 'src/app/shared/services/word-fetcher.service';
import { PropertyService } from 'src/app/shared/services/property.service';
import { Word } from 'src/app/shared/models/word.model';
import { FavoritesService } from 'src/app/shared/services/favorites.service';

@Component({
  selector: 'app-details',
  templateUrl: './details.component.html',
  styleUrls: ['./details.component.scss']
})

export class DetailsComponent implements OnInit {
  public index: number;
  word: Word;
  linguaCorsaInHtml: boolean;
  isFav: boolean;
  public star: string

  public switchesNames: {[key:string]: string };
  public arrayOfSwitches:{[key:string]: boolean};
  public wordKeys:Array<string>;


  constructor(private activatedRoute: ActivatedRoute, 
    private wordFetcherService: WordFetcherService, 
    private favoService: FavoritesService,
    private propertyService: PropertyService) {   }

  ngOnInit(): void {

    this.activatedRoute.paramMap.subscribe((params: ParamMap) => {
      if (params.get('index')) {
        this.index = Number(params.get('index'));
      } else {
        this.index = 0;
      }
    this.word = this.wordFetcherService.getThatWord(this.index);
    });

    this.arrayOfSwitches = this.propertyService.checkLocalStorage();  
    this.linguaCorsaInHtml = this.propertyService.linguaCorsa.value;

    this.switchesNames = this.linguaCorsaInHtml ? this.propertyService.switchesNamesCorsu : this.propertyService.switchesNamesFrancais ;
    
    this.wordKeys = Object.keys(this.word);
    
    this.favoService.checkLocalStorage();
    this.isFav = this.favoService.isFav(this.word.def) ;
    //console.log('is Fav observable ' + this.favoService.statusFav.value + '/ is fav fonction ' + this.favoService.isFav(this.word.def));
    this.isFav ? this.star = "star" : this.star = "star_border"

  }

  toggleFavo() {
    //console.log('this.word.def :' + this.word.def);
    this.favoService.toggleFav(this.word);
    this.isFav = !this.isFav;
    //console.log('toggle favo, is fav = ' + this.isFav)
    this.isFav ? this.star = "star" : this.star = "star_border"

  }
}
