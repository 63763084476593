import { Component, OnInit } from '@angular/core';
import { PropertyService } from '../shared/services/property.service';
import { Word } from '../shared/models/word.model';
import { FavoritesService } from '../shared/services/favorites.service';

@Component({
  selector: 'app-favorites',
  templateUrl: './favorites.component.html',
  styleUrls: ['./favorites.component.scss']
})
export class FavoritesComponent implements OnInit {

landingText: string;
public linguaCorsaInHtml: boolean;
public listOfFavos: Array<Word>;
public listOfKeys: Array<string>;
public arrayOfKeys: Array<string>;
favWord: Word;


constructor(
  private propertyService: PropertyService,
  private favoService: FavoritesService,) { }

ngOnInit(): void {
  this.linguaCorsaInHtml = this.propertyService.linguaCorsa.value;

  this.propertyService.currentPage.next('favorites');
  this.landingText = this.propertyService.landingText('favorites');
  this.favoService.checkLocalStorage();
  this.listOfFavos = this.favoService.listOfFavorites();
  this.listOfKeys = Object.keys(this.listOfFavos);
  //this.arg = [0,1,2];
  //console.log( this.listOfKeys + typeof(this.listOfKeys));
}

getThatFav(id:number): Word {
  return this.favWord = this.favoService.getFav(id);
}


}
