import { Component, OnInit } from '@angular/core';
import { WordFetcherService } from '../shared/services/word-fetcher.service';
import { PropertyService } from '../shared/services/property.service';
import { Word } from '../shared/models/word.model';

@Component({
  selector: 'app-results',
  templateUrl: './results.component.html',
  styleUrls: ['./results.component.scss']
})

export class ResultsComponent implements OnInit {
  public currentPage: string;
  searchText: string ;
  words: Word[];
  placeHolder: string;
  public landingText: string;
  linguaCorsaInHtml: boolean;

  constructor(private wordFetcherService: WordFetcherService, private propertyService: PropertyService) { 
    this.wordFetcherService.words.subscribe( (words: Word[]) => {
      this.words = words;
    })
  }

  ngOnInit(): void {
    this.propertyService.currentPage.next('search') ;
    this.linguaCorsaInHtml = this.propertyService.linguaCorsa.value;
    this.landingText = this.propertyService.landingText(this.propertyService.currentPage);
    //this.placeHolder= this.propertyService.placeHolder.value;
  }
  ngAfterContentChecked(): void {
    this.placeHolder = this.propertyService.placeHolder.value;
    }
   

  search(event) {
    this.linguaCorsaInHtml = this.propertyService.linguaCorsa.value;
    this.searchText = event.target.value;
    if (this.searchText !== '') {
      this.wordFetcherService.getWords(this.searchText);
      //console.log(this.words, this.searchText);
    } else {
      this.words=[]
    }
  }


}