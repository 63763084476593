
    <mat-list *ngIf="linguaCorsaInHtml" role="list" color="text-color">
        <mat-list-item role="listitem">
            <a  routerLink= "search" color="md-accent"> 
                <span class="material-icons">
                    search
                </span>
                Ricerca 
            </a>
        </mat-list-item>
        <mat-list-item role="listitem">
            <a  routerLink= "favorites"> 
                <span class="material-icons">
                    star_border
                </span>
                Priferiti
            </a>
        </mat-list-item>
        <mat-list-item role="listitem">
            <a  routerLink= "search-settings">
                <span class="material-icons">
                    settings
                </span>
                 Ozzioni
                </a>
            </mat-list-item>
        <mat-list-item role="listitem">
            <a  routerLink= "about">
                <span class="material-icons">
                    info
                </span>
                Infcor
            </a>
        </mat-list-item>
    </mat-list>
    <mat-list *ngIf="!linguaCorsaInHtml" role="list" color="text-color">
        <mat-list-item role="listitem">
            <a  routerLink= "search" color="md-accent"> 
                <span class="material-icons">
                    search
                </span>
                Recherche 
            </a>
        </mat-list-item>
        <mat-list-item role="listitem">
            <a  routerLink= "favorites"> 
                <span class="material-icons">
                    star_border
                </span>
                Favoris
            </a>
        </mat-list-item>
        <mat-list-item role="listitem">
            <a  routerLink= "search-settings">
                <span class="material-icons">
                    settings
                </span>
                 Options
                </a>
            </mat-list-item>
        <mat-list-item role="listitem">
            <a  routerLink= "about">
                <span class="material-icons">
                    info
                </span>
                Infcor
            </a>
        </mat-list-item>
    </mat-list>