import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class PropertyService {
  public currentPage:  BehaviorSubject<string> = new BehaviorSubject( 'app' );
  public placeHolder: BehaviorSubject<string> = new BehaviorSubject('Parolla à traduce');
  public menuVisible: boolean;
  public arrayOfSwitches: {[key:string]: boolean};
  public linguaCorsa: BehaviorSubject<boolean> = new BehaviorSubject( true );  

  constructor() { }
  
  ngOnInit() {
    this.linguaCorsa.subscribe( boule => boule);
    this.currentPage.subscribe ( texte => texte)
  }

  changeLingua() {
    this.linguaCorsa.next(!this.linguaCorsa.value);
    this.changePlaceHolder();
  }

  lingua(): string  {
    return this.linguaCorsa.value ? 'corsu' : 'francese' ;
  }

  queryLanguage() : string {
    return this.linguaCorsa.value ? "mot_corse" : "mot_francais";
  }

  landingText(page) : string {
    //console.log ('lingua value', this.linguaCorsa.value);
    if (page === 'search-settings') {
      return this.linguaCorsa.value ? "Ozzioni" : "Options";
    } 
    if (page === 'about') {
      return this.linguaCorsa.value ? "Infcor" : "Infcor";
    } 
    if (page === 'favorites') {
      return this.linguaCorsa.value ? "Priferiti" : "Favoris";
    } 
    else {
      return this.linguaCorsa.value ? "Corsu \u2192 Francese" : "Français \u2192 Corse";
    }
  }

  changePlaceHolder() {
     this.linguaCorsa.value ? this.placeHolder.next("Parolla à traduce") : this.placeHolder.next("Mot à traduire");
  }

  saveToDisk(arrayOfSwitches:{[key:string]:boolean}) {
    localStorage.setItem('arrayOfSwitches', JSON.stringify(arrayOfSwitches));
  }

  checkLocalStorage() {

    if (localStorage.hasOwnProperty('arrayOfSwitches') ) {
      this.arrayOfSwitches = JSON.parse(localStorage.getItem('arrayOfSwitches'));
    }
    else {
      this.arrayOfSwitches = { TALIANU:true, INGLESE:true, NATURA:false, PRUNUNCIA:true,
        DEFINIZIONE:true, ETIMULUGIA:true, GRAMMATICA:true, VARIANTESD:true, SINONIMI:true, 
        ANTONIMI:true, DERIVATICUMPOSTI:true, SPRESSIONIEPRUVERBII:true, ANALUGIE:true,
        CITAZIONIDAAUTORI:true, BIBLIOGRAFIA:true, INDICE:true};
    }
    return this.arrayOfSwitches;
  }
  
  switchesNamesCorsu: {[key:string]: string} = { TALIANU:"Talianu", INGLESE:"Inglese" ,NATURA: "Natura", PRUNUNCIA:"Prununzia",
    DEFINIZIONE:"Definizione", ETIMULUGIA:"Etimulugia", GRAMMATICA:"Grammatica", VARIANTESD:"Variante", SINONIMI:"Sinonimi", 
    ANTONIMI:"Antonimi", DERIVATICUMPOSTI:"Derivati Cumposti", SPRESSIONIEPRUVERBII:"Spressioni è Pruverbii", ANALUGIE:"Analugie", 
    CITAZIONIDAAUTORI:"Citazioni dà Autori", BIBLIOGRAFIA:"Bibliografia", INDICE: "Indice" };
  
  switchesNamesFrancais: {[key:string]: string} = { TALIANU:"Italien", INGLESE:"Anglais", NATURA:"Genre", PRUNUNCIA:"Prononciation", 
    DEFINIZIONE:"Définition en Corse", ETIMULUGIA:"Etymologie", GRAMMATICA:"Grammaire", VARIANTESD:"Variantes Graphiques", SINONIMI:"Synonymes",
    ANTONIMI:"Antonymes", DERIVATICUMPOSTI:"Dérivés Composés", SPRESSIONIEPRUVERBII:"Expressions et Proverbes",ANALUGIE:"Analogies", 
    CITAZIONIDAAUTORI:"Citations d'Auteurs", BIBLIOGRAFIA:"Bibliographie", INDICE:"Indice"};
}
