import { Component, OnInit } from '@angular/core';
import { PropertyService } from '../shared/services/property.service';

@Component({
  selector: 'app-menu',
  templateUrl: './menu.component.html',
  styleUrls: ['./menu.component.scss']
})
export class MenuComponent  {
  constructor(private propertyService: PropertyService) { }
  
  public linguaCorsaInHtml: boolean; 

  ngOnInit(): void {
    this.linguaCorsaInHtml = this.propertyService.linguaCorsa.value;

  }
  ngAfterContentChecked(): void {
    this.linguaCorsaInHtml = this.propertyService.linguaCorsa.value;

  }

}
