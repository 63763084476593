import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http'
import { BehaviorSubject, Observable } from 'rxjs';
import { filter, map } from 'rxjs/operators' ;
import { Word } from '../models/word.model';
import { PropertyService } from './property.service';

@Injectable({
  providedIn: 'root'
})
export class WordFetcherService {
	public words: BehaviorSubject<Word[]> = new BehaviorSubject( null );

	constructor( private http: HttpClient, private propertyService: PropertyService) { }
  
	getWords(searchText: string): void {
		let querylanguage = this.propertyService.queryLanguage();
	  	//searchText = 'casa';
	  	if(searchText === '') {
		  return
	  	} else {
			this.http.get<Word[]>('https://infcor.adecec.net/try/swift.php?langue='+ querylanguage +'&param=FRANCESE%20TALIANU%20INGLESE%20NATURA%20PRUNUNCIA%20DEFINIZIONE%20ETIMULUGIA%20GRAMMATICA%20VARIANTESD%20SINONIMI%20ANTONIMI%20DERIVATICUMPOSTI%20SPRESSIONIEPRUVERBII%20ANALUGIE%20CITAZIONIDAAUTORI%20BIBLIOGRAFIA%20INDICE&mot=' + searchText).subscribe( (words: Word[]) => this.words.next(words)) ;
		}
	}

	getOneWord(index: number) : Observable<Word[]> {
		//console.log('get one word ', this.words, this.words[index]);
		return this.words;
	}

	getThatWord(index: number) {
		return this.words.value[index];
	}
}
