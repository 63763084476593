<div  class="cust-results mat-display-1">
    <div *ngIf="linguaCorsaInHtml">
        <p fxLayout="row"> <span class="big-title" fxFlex="92"> {{ word.def }}</span>
            <button class="material-icons favorite" fxFlex="8" (click)="toggleFavo()">{{ star }}</button>
        </p>
        <p><span class="first">Français</span><span class="second"> : {{word.FRANCESE }}</span></p>
    </div>
    <div *ngIf="!linguaCorsaInHtml">
        <p fxLayout="row"> <span class="big-title" fxFlex="92"> {{ word.FRANCESE }}</span>
            <button class="material-icons favorite" fxFlex="8" (click)="toggleFavo()">{{ star }}</button>
        </p>
        <p><span class="first">Corsu</span><span class="second"> : {{word.def }}</span></p>
    </div>
    <div *ngFor="let switch of wordKeys;">
        <p *ngIf="arrayOfSwitches[switch]"> 
            <span class="first">{{ switchesNames[switch]}}</span><span class="second"> : {{word[switch]}}</span>
        </p>
    </div>
</div>