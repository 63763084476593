import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';

// System Components
import { HttpClientModule } from '@angular/common/http';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { ServiceWorkerModule } from '@angular/service-worker';
import { environment } from '../environments/environment';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

//Display enhancement Components
import { A11yModule } from '@angular/cdk/a11y';
import { CdkStepperModule } from '@angular/cdk/stepper';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatListModule } from '@angular/material/list';
import { MatMenuModule } from '@angular/material/menu';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatSliderModule } from '@angular/material/slider';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import {MAT_FORM_FIELD_DEFAULT_OPTIONS} from '@angular/material/form-field';
import { FlexLayoutModule } from '@angular/flex-layout';


//Compnents needed for content display
import { AboutComponent } from './about/about.component';
import { FavoritesComponent } from './favorites/favorites.component';
import { ResultsComponent } from './results/results.component';
import { MenuComponent } from './menu/menu.component';
import { DetailsComponent } from './results/details/details.component';
import { SearchSettingsComponent } from './search-settings/search-settings.component';
import { FavoritesDetailComponent } from './favorites/favorites-detail/favorites-detail.component';

@NgModule({
  declarations: [
    AppComponent,
    AboutComponent,
    FavoritesComponent,
    ResultsComponent,
    MenuComponent,
    DetailsComponent,
    SearchSettingsComponent,
    FavoritesDetailComponent,
    
  ],
  imports: [
    AppRoutingModule,
    BrowserModule,
    BrowserAnimationsModule,
    HttpClientModule,
   /* A11yModule,
    CdkStepperModule,*/
    FormsModule,
    ReactiveFormsModule,
    MatIconModule,
    MatInputModule,
    MatListModule,
    MatMenuModule,
    MatSidenavModule,
    MatSliderModule,
    MatSlideToggleModule,
    FlexLayoutModule,
    ServiceWorkerModule.register('ngsw-worker.js', { enabled: environment.production }),
  ],
  exports: [
    /*MatListModule,
    MatMenuModule,
    MatSidenavModule,*/
  ],
  providers: [
    { provide: MAT_FORM_FIELD_DEFAULT_OPTIONS, useValue: { appearance: 'fill' } },
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
