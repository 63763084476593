
<mat-sidenav-container class="menu-container">
  <mat-sidenav class="cust-menu" #sidenav (click)="sidenav.toggle()" mode="side">
    <app-menu></app-menu>
  </mat-sidenav>
  
  <mat-sidenav-content>
    <div fxLayout="row" class='cust-menu'>
      <button  class="cust-hamburger" fxFlex="10" mat-button (click)="sidenav.toggle()">
        <mat-icon aria-label="Side nav toggle icon">menu</mat-icon>
      </button>
      <button fxFlex="100" class="cust-button" (click)="showTitle()"> {{landingText}}</button>
    </div>
    <div class="main">
    <router-outlet ></router-outlet>
      
  </div> <!--- <app-adecec-container></app-adecec-container>	-->
  </mat-sidenav-content>
</mat-sidenav-container>
