import { Component, OnInit } from '@angular/core';
import { PropertyService } from '../shared/services/property.service';


@Component({
  selector: 'app-search-settings',
  templateUrl: './search-settings.component.html',
  styleUrls: ['./search-settings.component.scss']
})
export class SearchSettingsComponent implements OnInit {
  public arrayOfSwitches: {[key:string]: boolean};
  public arrayOfSwitchesKeys: Array<string>;
  public switchesNamesCorsu: {[key: string]: string};
  public switchesNamesFrancais: {[key:string]:string};
  public currentPage: string;
  landingText: string
  public linguaCorsaInHtml: boolean;
  
  constructor(private propertyService: PropertyService) { }

  ngOnInit(): void {
    this.linguaCorsaInHtml = this.propertyService.linguaCorsa.value;
    this.arrayOfSwitches = this.propertyService.checkLocalStorage();
    this.propertyService.currentPage.next('search-settings');
    this.landingText = this.propertyService.landingText('search-settings');

    //this.propertyService.checkLocalStorage();
    this.arrayOfSwitchesKeys = Object.keys(this.arrayOfSwitches);
    this.switchesNamesCorsu = this.propertyService.switchesNamesCorsu;
    this.switchesNamesFrancais = this.propertyService.switchesNamesFrancais;
  }

  toggleChange(s: boolean) {
    return !s ;
  }

  ngOnDestroy() {
    this.propertyService.saveToDisk(this.arrayOfSwitches);
  }
}
