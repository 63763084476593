import { Injectable } from '@angular/core';
import { Word } from '../models/word.model';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
@Injectable({
  providedIn: 'root'
})
export class FavoritesService {
  public statusFav:  BehaviorSubject<boolean>  = new BehaviorSubject( false );
  public afav: Word;
  public favList:Word[] ;

  constructor() {   }

  checkLocalStorage() {
    if (localStorage.hasOwnProperty('infcorFavorites')) {
      this.favList = JSON.parse(localStorage.getItem('infcorFavorites'));
    }
    else {
      this.afav = {
        id: Date.now(),
        def: '',
        FRANCESE:  '',
        TALIANU:  '',
        INGLESE:  '',    
        NATURA:  '',
        PRUNUNCIA:  '',
        DEFINIZIONE:  '',
        ETIMULUGIA:  '',
        GRAMMATICA:   '',
        VARIANTESD:   '',
        SINONIMI:   '',
        ANTONIMI:   '',
        DERIVATICUMPOSTI:   '',
        SPRESSIONIEPRUVERBII:   '',
        ANALUGIE:   '',
        CITAZIONIDAAUTORI:   '',
        BIBLIOGRAFIA:   '',
        INDICE:   '',
      };
      this.favList = [this.afav];
      localStorage.setItem('infcorFavorites', JSON.stringify(this.favList))
    }
  }
  ngOnInit() {
    this.statusFav.subscribe((state) => (state) );
  }


  isFav(key: string): boolean {
    //test sur favList, si l'element key existe, le résultat a une length != 0
    this.statusFav.next(this.favList.filter( (item) => ( item.def === key)).length !== 0);
    return this.statusFav.value;
  }

  removeFav(fav: Word) {
    //test sur favList le resultat est un nouvel array sans fav
    this.favList = this.favList.filter( (item) => ( item.def !== fav.def))
    localStorage.setItem('infcorFavorites', JSON.stringify(this.favList));
  }

  toggleFav(afav: Word) {
    this.statusFav.next(!this.statusFav.value);
    this.isFav(afav.def) ? this.removeFav(afav): this.saveFav(afav) ;
  }
  
  saveFav(afav:Word) {
    afav.id = Date.now();
    this.favList.push(afav);

    localStorage.setItem('infcorFavorites', JSON.stringify(this.favList));
  }

  getFav(id: number): Word {
    this.statusFav.next(true);
    return this.favList.filter( (item) => (item.id === id))[0];
  }

  listOfFavorites() :Word[] {
    return JSON.parse(localStorage.getItem('infcorFavorites'));
  }
}
