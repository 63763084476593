import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { ResultsComponent } from './results/results.component';
import { DetailsComponent } from './results/details/details.component';
import { FavoritesComponent } from './favorites/favorites.component';
import { AboutComponent } from './about/about.component';
import { SearchSettingsComponent } from './search-settings/search-settings.component';
import { FavoritesDetailComponent } from './favorites/favorites-detail/favorites-detail.component';


const routes: Routes = [
  { path: '', redirectTo: 'search', pathMatch: 'full' },
  { path: 'search', component: ResultsComponent },
  { path: 'search/:index', component: DetailsComponent },
  { path: 'favorites', component: FavoritesComponent },
  { path: 'favorites/:index', component: FavoritesDetailComponent },
  { path: 'about', component: AboutComponent },
  { path: 'search-settings', component: SearchSettingsComponent}
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
