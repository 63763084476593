<div fxLayout="row" fxLayoutAlign="center center" class="cust-search-bar">
    <input fxFlex="100" autocorrect="off" autocapitalize="none" autocomplete="off" spellcheck="false" class="form-control" (keyup)="search($event)" placeholder={{placeHolder}}>
</div>
<div role="list">
    <div role="listitem" class="cust-list-item" *ngFor="let word of words; let index = index">
        <div mat-line  *ngIf="linguaCorsaInHtml">
            <a  routerLinkActive='active' [routerLink]= "index" > {{ word.def }}</a>
        </div>
        <div mat-line class="list-secondary" *ngIf="linguaCorsaInHtml" >
            <a routerLinkActive='active' [routerLink]= "index" > {{ word.FRANCESE }} </a>
        </div>
        <div mat-line *ngIf="!linguaCorsaInHtml" >
            <a routerLinkActive='active' [routerLink]= "index" > {{ word.FRANCESE }} </a>
        </div>
        <div mat-line class="list-secondary" *ngIf="!linguaCorsaInHtml">
            <a routerLinkActive='active' [routerLink]= "index"> {{ word.def }}</a>
        </div>
    </div>
    <!--<mat-list-item  *ngFor="let word of words; let index = index">
        <div mat-line  *ngIf="linguaCorsaInHtml">
            <a  routerLinkActive='active' [routerLink]= "index" > {{ word.def }}</a>
        </div>
        <div mat-line class="list-secondary" *ngIf="linguaCorsaInHtml" >
            <a routerLinkActive='active' [routerLink]= "index" > {{ word.FRANCESE }} </a>
        </div>
        <div mat-line *ngIf="!linguaCorsaInHtml" >
            <a routerLinkActive='active' [routerLink]= "index"> {{ word.FRANCESE }} </a>
        </div>
        <div mat-line class="list-secondary" *ngIf="!linguaCorsaInHtml">
            <a routerLinkActive='active' [routerLink]= "index"> {{ word.def }}</a>
        </div>
    </mat-list-item> -->
</div>
