import { Component, OnInit } from '@angular/core';
import { PropertyService } from './shared/services/property.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {
  
  public currentPage: string;
  linguaCorsaInHtml: boolean;
  landingText: string;

  constructor(private propertyService: PropertyService) {}

  ngOnInit(): void {
    this.linguaCorsaInHtml = this.propertyService.linguaCorsa.value;

    this.currentPage = this.propertyService.currentPage.value;
    this.landingText = this.propertyService.landingText(this.propertyService.currentPage);

  }
  ngAfterContentChecked(): void {
    this.landingText = this.propertyService.landingText(this.propertyService.currentPage.value);
  }

  showTitle() {
      let component = this.propertyService.currentPage.value;
    if (component === 'app' || component === 'search') {
      this.propertyService.changeLingua();
      this.landingText = this.propertyService.landingText(this.propertyService.currentPage.value);
      this.linguaCorsaInHtml = this.propertyService.linguaCorsa.value;      
    }
    else {
      this.landingText = this.propertyService.landingText(this.propertyService.currentPage.value);
    }
  }
}

